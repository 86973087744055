import React, { FunctionComponent, useState, SyntheticEvent } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import Layout from '../../components/layout';
import BootstrapModal from '../../components/custom-modal';
import { homeLogoWithText } from '../../assets';
import './styles.scss';

const ContactPage: FunctionComponent<any> = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    isModalOpen: false,
    error: false,
    errorMessage: '',
  });

  const submitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const { data } = await axios({
      method: 'post',
      url: 'https://api.hexometer.com/v2/ql',
      data: {
        operationName: 'ContactFormSubmit',
        variables: {
          name: formData.name,
          email: formData.email,
          subject: `InspectContent: ${formData.subject}`,
          message: JSON.stringify(formData.message),
          emailTo: 'support@hexometer.com',
          productType: 'inspectcontent',
        },
        query:
          'mutation ContactFormSubmit($name: String!, $email: String!, $subject: String!, $message: String!, $emailTo: String) {\n  contactFormSubmit(name: $name, email: $email, subject: $subject, message: $message, emailTo: $emailTo) {\n    error\n    message\n    __typename\n  }\n}\n',
      },
    });
    setLoading(false);
    if (data && data.data && data.data.contactFormSubmit && data.data.contactFormSubmit.error) {
      setFormData({ ...formData, error: true, errorMessage: 'Something went wrong, please try again.' });
    } else {
      setFormData({
        ...formData,
        isModalOpen: !formData.isModalOpen,
        error: false,
        errorMessage: '',
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    }
  };

  const changeHandler = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const modalToggle = async (): Promise<void> => {
    await setFormData({
      ...formData,
      isModalOpen: !formData.isModalOpen,
    });
    // toggleLastURL()
  };

  return (
    <Layout
      title={'Customer support contacts | Inspect Content'}
      metaName={'description'}
      metaContent={'Customer support contacts | Inspect Content'}
    >
      <div className="logo-wrapper">
        <Link to="/">
          <span>{homeLogoWithText}</span>
        </Link>
      </div>
      <BootstrapModal
        show={formData.isModalOpen}
        onHide={modalToggle}
        title="Your message has been sent, we will come back to you shortly."
      />
      <div className="contact-block">
        <div className="main container">
          <div className="terms">
            <section>
              <h1>Feel free to contact us</h1>
              <form className="contact_form_box" onSubmit={e => !loading && submitHandler(e)}>
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      required
                      type="text"
                      className="input"
                      placeholder={'Your name'}
                      name="name"
                      value={formData.name}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      required
                      type="text"
                      className="input"
                      placeholder={'Your email'}
                      name="email"
                      value={formData.email}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="col-lg-12">
                    <input
                      required
                      type="text"
                      className="input"
                      placeholder={'Your Subject'}
                      name="subject"
                      value={formData.subject}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      required
                      className="textarea"
                      rows={5}
                      onChange={changeHandler}
                      name="message"
                      placeholder={'Type your message here'}
                      value={formData.message}
                    />
                  </div>
                  <div className="col-lg-12 footer-block">
                    <button className="button" disabled={loading}>
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
